import { takeEvery, all, put, call } from "redux-saga/effects";

// actionTypes
import * as actionTypes from "../redux/actionTypes";

import {
  invoiceStatusTableDataFetchSuccess,
  invoiceStatusTableDataFetchFailed,
  invoiceHistoryTableDataFetchSuccess,
  invoiceHistoryTableDataFetchFailed,
  ExternalInvoiceStautsTableDataFetchSuccess,
  ExternalInvoiceStautsTableDataFetchFailed,
  ExternalInvoiceHistoryTableDataFetchSuccess,
  ExternalInvoiceHistoryTableDataFetchFailed
} from "../redux/actions/invoiceActions";

// services
import {
  fetchInvoiceStatus,
  fetchInvoiceHistory,
  fetchExternalInvoiceStatus,
  fetchExternalInvoiceHistory 
} from "../api/services/invoiceService";

// Worker saga start

//invoice status start
function* loadInvoiceStatus(action) {
  try {
    const { data } = yield call(fetchInvoiceStatus,action.searchValue);
    // console.log(`invoiceStatus`, data)
    yield put(invoiceStatusTableDataFetchSuccess(data?.data.monthlyRecords));
  } catch (error) {
    yield put(invoiceStatusTableDataFetchFailed());
  }
}
//invoice status end

//invoice history start
function* loadInvoiceHistory() {
  try {
    const { data } = yield call(fetchInvoiceHistory);
      yield put(invoiceHistoryTableDataFetchSuccess(data?.data.internalInvoiceHistory));
  } catch (error) {
    yield put(invoiceHistoryTableDataFetchFailed());
  }
}
//invoice history end

//ExternalInvoice Status start
function* loadExternalInvoiceStatus(action) {
  try {
    const { data } = yield call(fetchExternalInvoiceStatus,action.searchValue);
    console.log(`externalinvoice`, data);
      yield put(ExternalInvoiceStautsTableDataFetchSuccess(data?.data.externalInvoice));
  } catch (error) {
    yield put(ExternalInvoiceStautsTableDataFetchFailed());
  }
}
//ExternalInvoice Status end

//ExternalInvoice History start
function* loadExternalInvoiceHistory() {
  try {
    const { data } = yield call(fetchExternalInvoiceHistory);
    console.log(`externalinvoiceHiastory`, data);
      // yield put(ExternalInvoiceHistoryTableDataFetchSuccess(data?.data?.externalInvoice));
  } catch (error) {
    yield put(ExternalInvoiceHistoryTableDataFetchFailed());
  }
}
//ExternalInvoice History end

//worker sage end

// watcher saga start

//invoice status watcher start
function* watchInvoiceStatus() {
  yield takeEvery(
    actionTypes.INVOICESTATUS_TABLE_DATA_FETCH_START,
    loadInvoiceStatus
  );
}
///Invoice status watch end

//Invoice history watcher start
function* watchInvoiceHistory() {
  yield takeEvery(
    actionTypes.INVOICEHISTORY_TABLE_DATA_FETCH_START,
    loadInvoiceHistory
  );
}
//Invoice History watcher end

//ExternalInvoice watcher start
function* watchExternalInvoiceStatus() {
  yield takeEvery(
    actionTypes.EXTERNALINVOICE_TABLE_DATA_FETCH_START,
    loadExternalInvoiceStatus
  );
}
//ExternalInvoice watcher end

//ExternalInvoice History watcher start
function* watchExternalInvoiceHistory() {
  yield takeEvery(
    actionTypes.EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_START,
    loadExternalInvoiceHistory
  );
}
//ExternalInvoice History watcher end
//watcher saga end

export function* invoiceSaga() {
  yield all([
    watchInvoiceStatus(),
    watchInvoiceHistory(),
    watchExternalInvoiceStatus(),
    watchExternalInvoiceHistory()
  ]);
}
