import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    clientMaster:[],
    totalCount:0,
    isError: false,
    isAdding: false,
    isUpdating: false,
    error: {}
}

export const clientMasterReducer = (state = inititalState, action) => {
    switch (action.type){
        case actionTypes.CLIENT_MASTER_TABLE_DATA_FETCH_START: 
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.CLIENT_MASTER_CREATE_START: 
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }
        
        case actionTypes.CLIENT_MASTER_UPDATE_START:
            return  {
                ...state,
                isUpdating: true,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.CLIENT_MASTER_TABLE_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                clientMaster: [...action.payload.clients],
                totalCount:action.payload.count,
                isError: false,
                error: {}
            }

        case actionTypes.CLIENT_MASTER_CREATE_SUCCESS: 
            return {
                ...state,
                clientMaster: [...action.payload.client],
                isAdding: false,
                error: {},
            }

            case actionTypes.CLIENT_MASTER_UPDATE_SUCCESS: 
            const updateClientMaster = [...state.clientMaster]
            const findIndexCM = updateClientMaster.findIndex(d => d.id === action.payload.id) 
            updateClientMaster[findIndexCM] = {...action.payload}
            return {
                ...state,
                clientMaster: [...updateClientMaster],
                isUpdating: false,
                isDataFetch: false,
                error: {}
            }

        case actionTypes.CLIENT_MASTER_TABLE_DATA_FETCH_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                clientMaster: [],
                isAdding: false,
                isUpdating: false,
                error: {...action.payload}
            }
        default: 
            return state
    }
}
