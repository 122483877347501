import * as actionTypes from '../actionTypes';

// **************************** INVOICE STATUS START ****************************
export const invoiceStatusTableDataFetchStart = (searchValue) => {
    return {
        type: actionTypes.INVOICESTATUS_TABLE_DATA_FETCH_START,
        searchValue:searchValue
    }
}

export const invoiceStatusTableDataFetchSuccess = (data) => {
    return {
        type: actionTypes.INVOICESTATUS_TABLE_DATA_FETCH_SUCCESS,
        payload: data
    }
}

export const invoiceStatusTableDataFetchFailed = () => {

    return {
        type: actionTypes.INVOICESTATUS_TABLE_DATA_FETCH_FAILED,
    }
}

// **************************** INVOICE STATUS START ****************************

// **************************** INVOICE HISTORY START ****************************
export const invoiceHistoryTableDataFetchStart = () => {
    return {
        type: actionTypes.INVOICEHISTORY_TABLE_DATA_FETCH_START
    }
}

export const invoiceHistoryTableDataFetchSuccess = (data) => {
    return {
        type: actionTypes.INVOICEHISTORY_TABLE_DATA_FETCH_SUCCESS,
        payload: data
    }
}

export const invoiceHistoryTableDataFetchFailed = () => {
    return {
        type: actionTypes.INVOICEHISTORY_TABLE_DATA_FETCH_FAILED
    }
}

// **************************** INVOICE HISTORY END ****************************

// **************************** EXTERNALINVOICE STATUS  START ****************************
export const ExternalInvoiceStautsTableDataFetchStart = (searchValue) => {
    return {
        type: actionTypes.EXTERNALINVOICE_TABLE_DATA_FETCH_START,
        searchValue:searchValue
    }
}

export const ExternalInvoiceStautsTableDataFetchSuccess = (data) => {
    return {
        type: actionTypes.EXTERNALINVOICE_TABLE_DATA_FETCH_SUCCESS,
        payload: data
    }
}

export const ExternalInvoiceStautsTableDataFetchFailed = () => {
    return {
        type: actionTypes.EXTERNALINVOICE_TABLE_DATA_FETCH_FAILED
    }
}

// **************************** EXTERNALINVOICE STATUS END ****************************

// **************************** EXTERNALINVOICE HISTORY  START ****************************
export const ExternalInvoiceHistoryTableDataFetchStart = () => {
    return {
        type: actionTypes.EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_START
    }
}

export const ExternalInvoiceHistoryTableDataFetchSuccess = (data) => {
    return {
        type: actionTypes.EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_SUCCESS,
        payload: data
    }
}

export const ExternalInvoiceHistoryTableDataFetchFailed = () => {
    return {
        type: actionTypes.EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_FAILED
    }
}

// **************************** EXTERNALINVOICE HISTORY END ****************************