import { takeEvery, all, put, call } from "redux-saga/effects";

// actionTypes
import * as actionTypes from "../redux/actionTypes";

// actions
import {
  loginActionFaild,
  loginActionSuccess,
} from "../redux/actions/loginActions";

// services
import { loginGoogleUser, loginUser } from "../api/services/loginService";

// Worker saga
function* loadUser(action) {
  try {
    const { token, userData } = yield call(loginUser, action.payload);
    console.log("userData :>> ", userData);
    console.log("action.payload :>> ", action.payload);
    yield localStorage.setItem(
      "authUser",
      JSON.stringify({ ...userData, token })
    );
    yield put(loginActionSuccess({ ...userData, token }));
  } catch (error) {
    yield put(loginActionFaild(error.response));
  }
}

function* loadGoogleUser(action) {
  try {
    const { token, userData } = yield call(loginGoogleUser, action.payload);
    console.log("userData :>> ", userData);
    console.log("action.payload :>> ", action.payload);
    yield localStorage.setItem(
      "authUser",
      JSON.stringify({ ...userData, token })
    );
    yield put(loginActionSuccess({ ...userData, token }));
  } catch (error) {
    yield put(loginActionFaild(error.response));
  }
}
// watcher saga
function* watchLogin() {
  yield takeEvery(actionTypes.LOGIN_START, loadUser);
  yield takeEvery(actionTypes.GOOGLE_LOGIN_START, loadGoogleUser);
}

export function* loginSaga() {
  yield all([watchLogin()]);
}
