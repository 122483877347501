import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    userSettings: [],
    isError: false,
    isAdding: false,
    isUpdating: false,
    error: {}
}

export const userSettingsReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.USER_SETTINGS_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.USER_SETTINGS_TABLE_DATA_POST_START:
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }

        case actionTypes.USER_SETTINGS_TABLE_DATA_PUT_START:
            return {
                ...state,
                isUpdating: true,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.USER_SETTINGS_TABLE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                isDataFetch: false,
                userSettings: [...action.payload],
                isError: false,
                // isAdding: false,
                // isUpdating: false,
                error: {}
            }

        case actionTypes.USER_SETTINGS_TABLE_DATA_POST_SUCCESS:
            const newUser = [action.payload, ...state.userSettings]
            return {
                ...state,
                userSettings: [...newUser],
                isAdding: false,
                error: {},
            }

        case actionTypes.USER_SETTINGS_TABLE_DATA_PUT_SUCCESS:
            const updateUser = [...state.userSettings]
            const findIndexU = updateUser.findIndex(d => d.id === action.payload.id)
            updateUser[findIndexU] = { ...action.payload }
            return {
                ...state,
                userSettings: [...updateUser],
                isUpdating: false,
                isDataFetch: false,
                error: {}
            }

        case actionTypes.USER_SETTINGS_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                userSettings: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }
        default:
            return state
    }
}
