import { publicRequest } from "../axiosConfig/publicRequest";

export const loginUser = (user) => {
  // console.log('user :>> ', user);
  return publicRequest.post("/auth/signIn", user).then((res) => res.data);
};

export const loginGoogleUser = (user) => {
  return publicRequest.post("/auth/google-login", user).then((res) => res.data);
};
