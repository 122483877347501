import { privateRequest } from "../axiosConfig/privateRequest";

export const fetchInvoiceStatus = (searchValue='') => {
    // console.log('searchValue', searchValue);
    return privateRequest.get(`/invoice-master?search=${searchValue}`)
}

export const fetchInvoiceHistory = () => {
    return privateRequest.get('/internal-invoice-history')
}

export const fetchExternalInvoiceStatus = (searchValue='') => {
    return privateRequest.get(`/external-invoice?search=${searchValue}`)
}

export const fetchExternalInvoiceHistory = () => {
    return privateRequest.get('external-invoice/external-invoice-history')
}
