import { combineReducers } from "redux";

// all reducers
import { loginReducer } from "./loginReducer";
import { masterReducer } from "./masterReducer/index";
import { invoiceReducer } from "./invoiceReducer";
import { reportReducer } from "./reportReducer";
import { processReducer } from './processReducer';
// import { processReducer } from "./processReducer/index";

export const rootReducer = combineReducers({
    login: loginReducer,
    master: masterReducer,
    invoice: invoiceReducer,
    process: processReducer,
    report: reportReducer,
})
